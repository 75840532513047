import { FooterElement, LogoPartners } from './index';
import { Sentence, SentenceSmall } from '../Typography';

import LogoLeReacteur from 'src/components/Logo/LogoLeReacteurFooter';
import Obfuscate from 'react-obfuscate';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import certificationQualiopi from '../../data/document/Certificat_F0411.pdf';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

const date = new Date();

const FooterSmall = ({ isBootcampPage, pageName }) => {
  return (
    <div>
      <div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LogoLeReacteur css={{ width: 75 }} />
          <div css={{ marginTop: 20 }}>
            <Sentence css={{ fontWeight: 'bold', fontSize: 20 }}>Le Reacteur</Sentence>
            <Sentence css={{ fontSize: 16 }}>55, Rue Etienne Marey</Sentence>
            <Sentence css={{ fontSize: 16 }}>75020 - Paris, France</Sentence>
          </div>

          <div css={{ listStyle: 'none', fontSize: 16, lineHeight: '24px' }}>
            <Obfuscate
              css={{
                marginTop: 5,
                textDecoration: 'none',
                color: colors.white,
                display: 'inline-block',
              }}
              email="contact@lereacteur.io"
              element="span"
            />
            <a
              css={{ textDecoration: 'none', color: colors.white, display: 'block' }}
              href="tel:+33179738728"
              aria-label="numéro de téléphone"
            >
              +33 (0)1.79.738.728
            </a>
            <SentenceSmall css={{ marginTop: 30 }}>
              Déclaration d'activité n°11755531275
            </SentenceSmall>
            <SentenceSmall>Référencement DataDock n°0029509</SentenceSmall>
          </div>
          {pageName === 'Homepage' ? (
            <div css={{ marginTop: 35 }}>
              <a href={certificationQualiopi} download>
                <StaticImage
                  src="../../data/images/institutionals/LogoQualiopi.png"
                  alt="logo Qualiopi"
                  aria-label="certificat Qualiopi"
                  // placeholder="none"
                  css={{
                    width: 215,
                    borderRadius: 5,
                  }}
                />
              </a>
            </div>
          ) : null}
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <FooterElement
              heading="Formations"
              item={[
                {
                  text: 'Développeur Blockchain - Temps Partiel',
                  title: 'Titre RNCP n°31114 - Bac+2',
                  url: '/bootcamp-web3/formation-developpeur-blockchain/',
                  isHidden: true,
                },
                {
                  text: 'Développeur Web et Web Mobile - E-Learning',
                  url: '/cours-informatique/formation-developpeur-web/',
                },
                {
                  text: 'Developpeur Web et Mobile - Temps Plein',
                  url: '/bootcamp-javascript/formation-developpeur-web-mobile/',
                },
                {
                  text: 'Developpeur Web et Mobile - Temps Partiel',
                  url: '/bootcamp-javascript/formation-developpeur-web-mobile-temps-partiel/',
                },
                {
                  text: 'Développeur Web et Mobile - Alternance',
                  url: '/formation-alternance/concepteur-developpeur-applications/',
                },
                {
                  text: 'Préparation HTML/CSS/JS - Formation gratuite',
                  url: '/apprendre-a-coder-gratuitement/',
                },
              ]}
            />
            <FooterElement
              heading="Entreprises"
              item={[
                { text: 'Formation React + Redux', url: '/formation/react-redux/' },
                { text: 'Formation React Native', url: '/formation/react-native/' },
                { text: 'Formation React Avancé', url: '/formation/react-avance/' },
                { text: 'Formation TypeScript', url: '/formation/typescript/' },
                { text: 'Formation Node.js', url: '/formation/node-js/' },
              ]}
            />
            <FooterElement
              heading="Certifications"
              item={[
                {
                  text: `Concepteur Développeur d'Applications - RNCP6`,
                  url: '/titre-rncp-concepteur-developpeur-d-applications',
                },
                {
                  text: 'Développeur Web et Web Mobile - RNCP5',
                  url: '/titre-rncp-developpeur-web-et-web-mobile',
                },
                {
                  text: 'Développeur Web - RNCP5',
                  url: '/titre-rncp-developpeur-web',
                },
                {
                  text: 'Exploiter la Blockchain',
                  url: '/certification-professionnelle-exploiter-la-blockchain-dans-le-developpement-d-applications',
                  isHidden: true,
                },
                {
                  text: 'Concepteur - Développeur d’applications Full Stack',
                  url: '/cours-informatique/formation-developpeur-full-stack/',
                },
              ]}
            />
            <FooterElement
              heading="Extra"
              item={[
                { text: 'Apprendre à coder gratuitement', url: '/apprendre-a-coder-gratuitement/' },
                {
                  text: 'Nos élèves créent votre MVP',
                  url: '/produit-minimum-viable-gratuit',
                },
              ]}
            />
            <FooterElement
              heading="À Propos"
              item={[
                { text: 'Financements', url: '/financements/' },
                { text: 'Événements', url: '/evenements/' },
                { text: 'Blog', url: '/blog/' },
                /* { text: 'Podcast', url: '/podcast/' }, */
                { text: 'Questions fréquentes', url: '/faq/' },
                { text: 'Nous contacter', url: '/contact/' },
                { text: 'Recruter nos élèves', url: '/recruteurs/' },
                { text: "Modalités d'accès", url: '/modalites-d-acces/' },
                { text: 'Politique de confidentialité', url: '/politique-de-confidentialite/' },
              ]}
            />
            <FooterElement
              heading="Suivez-nous"
              item={[
                {
                  text: 'LinkedIn',
                  url: 'https://www.linkedin.com/school/le-reacteur/',
                },
                {
                  text: 'Instagram',
                  url: 'https://www.instagram.com/lereacteur.io/',
                },
                {
                  text: 'Facebook',
                  url: 'https://www.facebook.com/LeReacteurIO/',
                },
                {
                  text: 'YouTube',
                  url: 'https://www.youtube.com/channel/UCZiSHyumY80PJWHUr9jiDvQ',
                },
                { text: 'GitHub', url: 'https://github.com/LeReacteur' },
                // { text: 'Twitter', url: 'https://twitter.com/lereacteurIO' },
                /* { text: 'Medium', url: 'https://medium.com/le-reacteur' }, */
              ]}
            />
          </div>
        </div>
      </div>
      <div
        css={{
          marginTop: 30,
          paddingTop: 30,
          display: 'flex',
          flexDirection: 'column',
          borderTop: '1px solid #2B2166',
          marginBottom: isBootcampPage ? 40 : null,
        }}
      >
        <LogoPartners />
        <SentenceSmall
          css={{
            fontSize: 14,
            paddingTop: 30,
            paddingBottom: 30,
            textAlign: 'center',
            [mediaQueries.phoneOnly]: {
              whiteSpace: 'pre-wrap',
            },
          }}
        >
          {`${date.getFullYear()} © Le Reacteur  \nTous droits réservés - Made with React`}
        </SentenceSmall>
      </div>
    </div>
  );
};
export default FooterSmall;
